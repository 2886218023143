<template>
	<div class="container">
		<div class="main">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{path:'/product-list'}">产品中心</el-breadcrumb-item>
				<el-breadcrumb-item>{{productData.name}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="product">
				<div class="product-name">{{productData.name}}</div>
				<div class="product-detail" v-html="productData.detail"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from "@/api/index";

	export default {
		data: function() {
			return {
				productData: {
					id: "",
					name: "",
					detail: ""
				}
			};
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				var self = this;
				api.getProductDetail(this.$route.query.id).then(function(resp) {
					if (resp.success) {
						if (resp.data != null)
							self.productData = resp.data;
						else
							self.$router.push("/");
					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped="true">
	.container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		.main{
			width: 1100px;
			padding: 30px 0px 30px 0px;
			.product {
				width: 1100px;
			
				.product-name {
					font-size: 2em;
					margin: 50px 0 50px 0;
					text-align: center;
				}
			
				.product-detail {
					margin-bottom: 50px;
				}
			}
		}
		
	}
</style>
